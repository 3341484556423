import React, { useState, useEffect } from 'react'
// plugin that creates slider
import Slider from 'nouislider'
import styled from 'styled-components'
import '../../css/slider.scss'

export default function Budget(props) {
  const [budget, setBudget] = useState([20, 250])
  useEffect(() => {
    if (
      !document.getElementById('budgetSlider').classList.contains('noUi-target')
    ) {
      Slider.create(document.getElementById('budgetSlider'), {
        start: budget,
        connect: [false, true, false],
        step: 5,
        range: { min: 20, max: 250 },
      }).on('update', values => {
        setBudget(values)
        props.getBudget(values)
      })
    }
    return function cleanup() {}
  })
  return (
    <Wrapper>
      <Header>Give us an idea of your budget? (p/hr p/head)</Header>
      <LabelWrapper>
        <Label>€{props.budgetVal[0]}</Label>
        <Label>to</Label>
        <Label>€{props.budgetVal[1]}</Label>
      </LabelWrapper>

      <div id="budgetSlider" className="slider-primary" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 800px) {
    width: 80%;
    margin-left: 20px;
  }
`

const Header = styled.h3`
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  padding-right: 2vh;
  flex: 1;
  @media (max-width: 800px) {
    width: 80%;
  }
`
const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const Label = styled.h3`
  text-align: center;
  margin-bottom: 2vh;
  color: #999;
  @media (max-width: 800px) {
    font-size: 22px;
  }
`
