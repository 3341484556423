import React from 'react'
import styled from 'styled-components'
import TextInput from '../TextInput'

export default function ClientAbout(props) {
  // handle validation
  let errors = {}
  if (!props.email) {
    errors.email = ''
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(props.email)) {
    errors.email = 'invalid email address'
  }
  if (!props.password) {
    errors.password = ''
  } else if (props.password.length < 6) {
    errors.password = 'password must be at least 6 characters'
  }

  return (
    <Wrapper>
      <Header>Some information about you:</Header>
      <TextInput
        type="text"
        placeholder="Your Name..."
        onChange={e => props.getName(e.target.value)}
        value={props.name}
      />
      <TextInput
        margin
        type="text"
        placeholder="Your Email..."
        onChange={e => props.getEmail(e.target.value)}
        value={props.email}
      />
      <ErrorText>{errors.email}</ErrorText>
      <TextInput
        margin
        type="text"
        placeholder="Your Telephone Number..."
        onChange={e => props.getPhone(e.target.value)}
        value={props.phone}
      />
      {props.user ? (
        <div />
      ) : (
        <>
          <TextInput
            type="password"
            placeholder="Enter a password to login in the future..."
            onChange={e => {
              props.getPassword(e.target.value)
            }}
            value={props.password}
          />
          <ErrorText>{errors.password}</ErrorText>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 800px) {
    width: 80%;
    margin-left: 20px;
  }
`

const Header = styled.h3`
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
`

const ErrorText = styled.p`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #f90000;
`
