import React from 'react'
import styled from 'styled-components'
import CB from '../Checkbox'

export default function HireRoles(props) {
  return (
    <Wrapper>
      <Header>What roles are you looking to hire for?</Header>
      <CheckWrap>
        <CB
          checked={props.developer}
          color="#777"
          onChange={() => props.setDeveloper(prevValue => !prevValue)}
          label="Developer"
        />
        <CB
          checked={props.designer}
          color="#777"
          onChange={() => props.setDesigner(prevValue => !prevValue)}
          label="Designer"
        />
        <CB
          checked={props.leader}
          color="#777"
          onChange={() => props.setLeader(prevValue => !prevValue)}
          label="Leader"
        />
      </CheckWrap>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 800px) {
    width: 80%;
    margin-left: 20px;
  }
`

const Header = styled.h3`
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  padding-right: 2vh;
  flex: 1;
  @media (max-width: 800px) {
    width: 80%;
    flex-direction: column;
  }
`

const CheckWrap = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`
