import React from 'react'
import HireContact from '../components/hire page/HireContact'
import Layout from '../components/layout'

export default function Hire() {
  return (
    <Layout>
      <HireContact />
    </Layout>
  )
}
