import React, { useEffect, useState } from 'react'
// plugin that creates slider
import Slider from 'nouislider'
import styled from 'styled-components'
import '../../css/slider.scss'

export default function CompanySize(props) {
  const [companySize, setCompanySize] = useState(10)
  useEffect(() => {
    if (
      !document
        .getElementById('companySizeSlider')
        .classList.contains('noUi-target')
    ) {
      Slider.create(document.getElementById('companySizeSlider'), {
        start: companySize,
        connect: [true, false],
        step: 5,
        range: { min: 0, max: 500 },
      }).on('update', values => {
        setCompanySize(Math.round(values))
        props.getCompanySize(Math.round(values))
      })
    }
    return function cleanup() {}
  })
  const companyLabel = companySize === 1 ? ' employee' : ' employees'
  return (
    <Wrapper>
      <Header>Size of your company (employees)?</Header>
      <Label>{companySize + companyLabel}</Label>
      <div id="companySizeSlider" className="slider-primary" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 800px) {
    width: 80%;
    margin-left: 20px;
  }
`

const Header = styled.h3`
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  padding-right: 2vh;
  flex: 1;
  @media (max-width: 800px) {
    width: 80%;
  }
`

const Label = styled.h3`
  text-align: center;
  margin-bottom: 2vh;
  color: #999;
  @media (max-width: 800px) {
    font-size: 22px;
  }
`
