import React, { useState, useEffect } from 'react'
import Button from 'components/CustomButtons/Button.jsx'
import HireSelect from './HireSelect'
import firebase from '../../firebase'
import GridContainer from 'components/Grid/GridContainer.jsx'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Animation from './Animation'
import ErrorModal from '../ErrorModal'
import ClientAbout from './ClientAbout'
import HireRoles from './HireRoles'
import TeamSize from './TeamSize'
import HireHowLong from './HireHowLong'
import Budget from './Budget'
import CompanySize from './CompanySize'
import Hours from './Hours'
import TeamLoaction from './TeamLocation'
import HireDatePicker from './HireDatePicker'
import ProjectType from './ProjectType'
import AnythingElse from './AnythingElse'

export default function HireContact(props) {
  const [developer, setDeveloper] = useState(false)
  const [designer, setDesigner] = useState(false)
  const [leader, setLeader] = useState(false)
  const [existingTeam, setExistingTeam] = useState(false)
  const [distinctProject, setDistinctProject] = useState(false)
  const [consultancy, setConsultancy] = useState(false)
  const [knowMore, setKnowMore] = useState(false)
  const [user, setUser] = useState(false)
  const role = 'Client'
  const [loading, setLoading] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [skills, setSkills] = useState([])
  const [teamSize, setTeamSize] = useState('')
  const [companySize, setCompanySize] = useState('')
  const [duration, setDuration] = useState('')
  const [budget, setBudget] = useState([])
  const [hours, setHours] = useState('')
  const [teamLocation, setTeamLocation] = useState('')
  const [startDate, setStartDate] = useState('')
  const [password, setPassword] = useState('')
  const [notes, setNotes] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [perm, setPerm] = useState(false)

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user)
      } else {
        setUser(null)
      }
    })
    return () => unsubscribe()
  }, [])
  const onValuePass = value => {
    setSkills(value)
  }

  const openModalHandler = () => {
    setIsShowing(true)
  }

  const closeModalHandler = () => {
    setIsShowing(false)
  }

  const getTeamSize = teamSize => {
    setTeamSize(teamSize)
  }

  const getCompanySize = companySize => {
    setCompanySize(companySize)
  }

  const getDuration = howLong => {
    setDuration(howLong)
  }

  const getBudget = budget => {
    setBudget(budget)
  }

  const getHours = hours => {
    setHours(hours)
  }
  const getPermStatus = () => {
    setPerm(!perm)
  }

  const getLocation = location => {
    setTeamLocation(location)
  }

  const getDate = date => {
    setStartDate(date)
  }

  const getPassword = password => {
    setPassword(password)
  }

  const getExtra = notes => {
    setNotes(notes)
  }

  const getName = name => {
    setName(name)
  }

  const getEmail = email => {
    setEmail(email)
  }

  const getPhone = telephone => {
    setPhone(telephone)
  }

  const doSaveProject = () => {
    const projectDetails = {
      developer: developer,
      designer: designer,
      leader: leader,
      existingTeam: existingTeam,
      distinctProject: distinctProject,
      consultancy: consultancy,
      knowMore: knowMore,
      staffMembers: teamSize,
      howLong: duration,
      priceRange: budget,
      Remote: teamLocation,
      startDate: startDate.startDate,
      companySize: companySize,
      permanantHire: perm,
      hours: hours,
      notes: notes,
      skills: skills,
      name: name,
      telephone: phone,
      email: email,
    }
    const projectRef = firebase.db.collection('project').doc(email)
    projectRef.set(projectDetails)
    gtag_report_conversion('https://www.flexatal.com/thanks/')
  }

  const gtag_report_conversion = url => {
    if (window.gtag) {
      var callback = function() {
        if (typeof url != 'undefined') {
          window.location = url
        }
      }
      window.gtag('event', 'conversion', {
        send_to: 'AW-676587932/p3G4CK-i-7kBEJzTz8IC',
        event_callback: callback,
      })
      return false
    }
  }

  const saveProject = () => {
    if (user) {
      userSaveProject()
    } else {
      newUserSaveProject()
    }
  }

  const newUserSaveProject = async () => {
    if (
      teamSize === '' ||
      duration === '' ||
      budget === [] ||
      teamLocation === '' ||
      startDate === '' ||
      companySize === '' ||
      skills === [] ||
      name === '' ||
      phone === '' ||
      email === '' ||
      password === ''
    ) {
      openModalHandler()
    } else {
      setLoading(true)

      const aValue = []

      for (let i = 0; i < skills.length; i++) {
        let x = skills[i].value

        aValue.push(x)
      }
      const clientRef = firebase.db.collection('client').doc(email)
      const userRef = firebase.db.collection('users').doc(email)
      const clientDetails = {
        email: email,
        name: name,
        telephone: phone,
        role: role,
      }

      await firebase.register(name, email, password)
      await firebase.login(email, password)
      await clientRef.set(clientDetails)
      await userRef.set(clientDetails)
      setSkills(aValue)
      setTimeout(() => {
        doSaveProject()
      }, 1500)
    }
  }

  const userSaveProject = async () => {
    if (
      teamSize === '' ||
      duration === '' ||
      budget === [] ||
      teamLocation === '' ||
      startDate === '' ||
      companySize === '' ||
      skills === [] ||
      name === '' ||
      phone === '' ||
      email === ''
    ) {
      openModalHandler()
    } else {
      setLoading(true)
      const aValue = []
      for (let i = 0; i < skills.length; i++) {
        let x = skills[i].value
        aValue.push(x)
      }
      setSkills(aValue)
      setTimeout(() => {
        doSaveProject()
      }, 1500)
    }
  }

  return loading ? (
    <Animation />
  ) : (
    <Wrapper>
      {isShowing ? <Backdrop onClick={closeModalHandler}></Backdrop> : null}
      <form
        onSubmit={e => {
          e.preventDefault()
          saveProject()
        }}
      >
        <div style={{ textAlign: 'left', width: '60%', margin: 'auto' }}>
          <Header>Hire Talent</Header>
          <SubHeader>
            Tell us what you need and we will start allocating your team:
          </SubHeader>
        </div>
        <ClientAbout
          user={user}
          email={email}
          name={name}
          phone={phone}
          password={password}
          getName={getName}
          getPhone={getPhone}
          getEmail={getEmail}
          getPassword={getPassword}
        />
        <HireRoles
          designer={designer}
          setDesigner={setDesigner}
          developer={developer}
          setDeveloper={setDeveloper}
          leader={leader}
          setLeader={setLeader}
        />
        <div>
          <div>
            <GridContainer justify="center">
              <SelectGrid>
                <HireSelect
                  developer={developer}
                  designer={designer}
                  leader={leader}
                  onValuePass={value => onValuePass(value)}
                />
              </SelectGrid>
            </GridContainer>
          </div>
        </div>
        <ProjectType
          existingTeam={existingTeam}
          setExistingTeam={setExistingTeam}
          distinctProject={distinctProject}
          setDistinctProject={setDistinctProject}
          consultancy={consultancy}
          setConsultancy={setConsultancy}
          knowMore={knowMore}
          setKnowMore={setKnowMore}
        />
        <CompanySize getCompanySize={getCompanySize} />
        <TeamSize getTeamSize={getTeamSize} />
        <HireHowLong
          permValue={perm}
          getPermStatus={getPermStatus}
          getDuration={getDuration}
        />
        <Budget budgetVal={budget} getBudget={getBudget} />
        <Hours getHours={getHours} />
        <TeamLoaction getLocation={getLocation} />
        <HireDatePicker getDate={getDate} />
        <AnythingElse getExtra={getExtra} />
        <ButtonWrap>
          <Button round color="warning" size="lg" onClick={() => saveProject()}>
            Let's connect you
          </Button>
        </ButtonWrap>
        <ErrorModal
          className="modal"
          show={isShowing}
          close={closeModalHandler}
        >
          All fields are required to enable us to connect you with the best
          talent.
        </ErrorModal>
      </form>
    </Wrapper>
  )
}

const Backdrop = styled.div`
  background-color: rgba(48, 49, 48, 0.62);
  height: 10000%;
  position: fixed;
  transition: all 1.3s;
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #232323;
`

const Header = styled.h1`
  font-size: 5vh;
  color: #ffffff;
  padding: 5% 0 3% 0;
  font-weight: bold;
  text-align: center;
  border-radius: 16px;
`

const SubHeader = styled.h1`
  font-size: 36px;
  color: #ffffff;

  font-weight: bold;
`

const SelectGrid = styled.div`
  width: 60%;
  @media (max-width: 800px) {
    width: 70%;
    margin-left: 10%;
  }
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4vh 0;
`
