import React, { useEffect, useState } from 'react'
// plugin that creates slider
import Slider from 'nouislider'
import styled from 'styled-components'
import '../../css/slider.scss'
import CB from '../Checkbox'

export default function HireHowLong(props) {
  const [howLong, setHowLong] = useState(26)
  useEffect(() => {
    if (
      !document
        .getElementById('howLongSlider')
        .classList.contains('noUi-target')
    ) {
      Slider.create(document.getElementById('howLongSlider'), {
        start: howLong,
        connect: [true, false],
        step: 1,
        range: { min: 1, max: 52 },
      }).on('update', values => {
        setHowLong(Math.round(values))
        props.getDuration(Math.round(values))
      })
    }
    return function cleanup() {}
  })
  const weekLabel = howLong === 1 ? ' week' : ' weeks'
  return (
    <Wrapper>
      <Header>How long do you need us for?(weeks)</Header>
      <Label>{howLong + weekLabel}</Label>
      <div id="howLongSlider" className="slider-primary" />
      <CB
        color="#777"
        checked={props.permValue}
        onChange={() => props.getPermStatus()}
        label="Permanent"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 800px) {
    width: 80%;
    margin-left: 20px;
  }
`

const Header = styled.h3`
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  padding-right: 2vh;
  flex: 1;
  @media (max-width: 800px) {
    width: 80%;
  }
`
const Label = styled.h3`
  text-align: center;
  margin-bottom: 2vh;
  color: #999;
  @media (max-width: 800px) {
    font-size: 22px;
  }
`
