import React from 'react'
import clsx from 'clsx'
import Select from 'react-select'
import { emphasize, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NoSsr from '@material-ui/core/NoSsr'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CancelIcon from '@material-ui/icons/Cancel'
import PropTypes from 'prop-types'

const developer = [
  { label: 'React.js' },
  { label: 'Angular.js' },
  { label: 'Vue.js' },
  { label: 'PHP' },
  { label: 'Firebase' },
  { label: 'SQL' },
  { label: 'NOSQL' },
  { label: 'Python' },
  { label: 'Golang' },
  { label: 'QA' },
  { label: 'UI' },
  { label: 'UX' },
  { label: 'DevOps' },
  { label: 'React Native' },
  { label: 'Mobile Development' },
  { label: 'Developer' },
  { label: 'Software Engineer' },
  { label: 'JavaScript' },
  { label: 'Swift' },
  { label: 'C#' },
  { label: 'C++' },
  { label: 'Java' },
  { label: 'C' },
  { label: 'Cobol' },
  { label: 'Matlab' },
  { label: 'Objective C' },
  { label: 'F#' },
  { label: 'Fortan' },
  { label: 'VHDL' },
  { label: 'FPGA' },
  { label: 'Flex' },
  { label: 'Groovy' },
  { label: 'Haskell' },
  { label: 'Kotlin' },
  { label: 'Pascal' },
  { label: 'Ruby' },
  { label: 'Rust' },
  { label: 'Simulink' },
  { label: 'XBase' },
  { label: 'Shell Script' },
  { label: 'Bash' },
  { label: 'Data Engineer' },
  { label: 'Big Data' },
  { label: 'Data Science' },
  { label: 'Kubernetes' },
  { label: 'Terraform' },
  { label: 'AWS' },
  { label: 'GCP' },
  { label: 'Azure' },
  { label: 'Cloud' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}))

const designer = [
  { label: 'Graphics' },
  { label: 'Design' },
  { label: 'Animation' },
  { label: 'Branding' },
  { label: 'UI/UX' },
  { label: 'web' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}))

const leader = [
  { label: 'Project Management' },
  { label: 'Scrum Master' },
  { label: 'CIO' },
  { label: 'CTO' },
  { label: 'Solutions Architect' },
  { label: 'Agile Coach' },
  { label: 'CDO' },
  { label: 'Engineering Lead' },
  { label: 'DevOps Lead' },
  { label: 'Cloud Lead' },
  { label: 'Programme Manager' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}))

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 100,
  },
  input: {
    display: 'flex',
    padding: 5,
    height: 'auto',
    width: '100% !important',
    outline: 'none',
    borderBottom: '1px solid white',
    '&:hover': {
      borderBottom: '1px solid #ff9000 !important',
      zIndex: 3,
    },
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
    backgroundColor: '#ff9000',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 18,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 24,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 20,
    color: '#777',
  },
  paper: {
    position: 'absolute',
    zIndex: 1000,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}))

function NoOptionsMessage(props) {
  return (
    <p style={{ fontSize: 18, color: '#000' }}>
      Select which roles you are looking for to see options
    </p>
  )
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  )
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        color: '#232323',
        backgroundColor: '#dedede',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
}

function Placeholder(props) {
  return (
    <Typography
      color="textPrimary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

function ValueContainer(props) {
  return (
    <div
      style={{ paddingTop: 10, paddingBottom: 10 }}
      className={props.selectProps.classes.valueContainer}
    >
      {props.children}
    </div>
  )
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

export default function HireSelect(props) {
  const classes = useStyles()
  const [multi, setMulti] = React.useState(null)
  const [list, setList] = React.useState(
    [].map(suggestion => ({
      value: suggestion.label,
      label: suggestion.label,
    }))
  )

  React.useEffect(() => {
    const newArr = [
      ...(props.developer === true ? developer : []),
      ...(props.designer === true ? designer : []),
      ...(props.leader === true ? leader : []),
    ]
    setList(newArr)
  }, [props.designer, props.developer, props.leader])

  function handleChangeMulti(value) {
    setMulti(value)

    props.onValuePass(value)
  }

  return (
    <div className={classes.root}>
      <NoSsr>
        <div className={classes.divider} />
        <Select
          classes={classes}
          inputId="react-select-multiple"
          placeholder="Select appropriate skills"
          options={list}
          NoOptionsMessage={NoOptionsMessage}
          components={components}
          value={props.setValue ? props.setValue : multi}
          onChange={handleChangeMulti}
          isMulti
        />
      </NoSsr>
    </div>
  )
}
