import React, { useState } from 'react'
import Datetime from 'react-datetime'
import FormControl from '@material-ui/core/FormControl'
import styled from 'styled-components'

export default function HireDatePicker(props) {
  const [startDate, setStartDate] = useState('')
  let yesterday = Datetime.moment().subtract(1, 'day')
  let valid = function(current) {
    return current.isAfter(yesterday)
  }

  const onDateChange = name => {
    setStartDate({
      startDate: name._d.toLocaleString('en-GB', {
        timeZone: 'UTC',
        hour12: false,
        month: 'short',
        year: 'numeric',
        day: 'numeric',
      }),
      open: false,
    })
    props.getDate({
      startDate: name._d.toLocaleString('en-GB', {
        timeZone: 'UTC',
        hour12: false,
        month: 'short',
        year: 'numeric',
        day: 'numeric',
      }),
    })
  }
  return (
    <Wrapper>
      <Header>When do you need us?</Header>
      <FormControl fullWidth>
        <Datetime
          isValidDate={valid}
          timeFormat={false}
          dateFormat="LL"
          closeOnSelect={true}
          onChange={onDateChange}
          inputProps={{
            name: 'startDate',
            id: 'float',
            placeholder: 'What date do you need us to start?',
            readOnly: true,
          }}
        />
      </FormControl>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 800px) {
    width: 80%;
    margin-left: 20px;
  }
`

const Header = styled.h3`
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  padding-right: 2vh;
  flex: 1;
  @media (max-width: 800px) {
    width: 80%;
  }
`
