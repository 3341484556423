import React, { useState } from 'react'
import styled from 'styled-components'
import TextArea from '../TextArea'

export default function AnythingElse(props) {
  const [notes, setNotes] = useState('')
  const handleNotes = e => {
    setNotes(e.target.value)
    props.getExtra(e.target.value)
  }
  return (
    <Wrapper>
      <Header>Anything else we should know?</Header>
      <TextArea
        rows="5"
        cols="5"
        placeholder="Further information goes here..."
        onChange={handleNotes}
        value={notes}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 800px) {
    width: 80%;
    margin-left: 20px;
  }
`

const Header = styled.h3`
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  padding-right: 2vh;
  flex: 1;
  @media (max-width: 800px) {
    width: 80%;
  }
`
