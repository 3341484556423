import React, { Component } from 'react'
import Particles from 'react-particles-js'
import styled from 'styled-components'
import config from './particlesjs-config.json'

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: #232323;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const Heading = styled.h1`
  font-size: 6vh;
  font-weight: bold;
  padding: 0 5%;
  margin-bottom: 1vh;
  color: white;
  justify-content: flex-end;
`

export default class Animation extends Component {
  render() {
    return (
      <Wrapper>
        <Particles params={config} />
        <Heading>Connecting with freelancers...</Heading>
      </Wrapper>
    )
  }
}
