import React from 'react'
import styled from 'styled-components'
import CB from '../Checkbox'

export default function ProjectType(props) {
  return (
    <Wrapper>
      <Header>Project Type:</Header>
      <CheckWrap>
        <CB
          checked={props.existingTeam}
          onChange={() => props.setExistingTeam(prevValue => !prevValue)}
          label="Support Existing Team"
          color="#777"
        />
        <CB
          checked={props.distinctProject}
          onChange={() => props.setDistinctProject(prevValue => !prevValue)}
          label="Distinct Project"
          color="#777"
        />
        <CB
          checked={props.consultancy}
          onChange={() => props.setConsultancy(prevValue => !prevValue)}
          label="Consultancy"
          color="#777"
        />
        <CB
          checked={props.knowMore}
          color="#777"
          onChange={() => props.setKnowMore(prevValue => !prevValue)}
          label="I just want to know more about Flexatal"
        />
      </CheckWrap>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 800px) {
    width: 80%;
    margin-left: 20px;
  }
`

const Header = styled.h3`
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  padding-right: 2vh;
  flex: 1;
  @media (max-width: 800px) {
    width: 80%;
  }
`

const CheckWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`
