import React, { useState } from 'react'
import styled from 'styled-components'

export default function TeamLocation(props) {
  const [teamLocation, setTeamLoaction] = useState('')
  const handleChange = event => {
    setTeamLoaction(event.target.value)
    props.getLocation(event.target.value)
  }
  return (
    <Wrapper>
      <Header>Would you consider remote workers?</Header>
      <RadioWrap>
        <Item>
          <RadioButton
            type="radio"
            value="yes"
            name="yesButton"
            checked={teamLocation === 'yes'}
            onChange={handleChange}
          />
          <RadioButtonLabel />
          <div style={{ color: '#777', fontSize: 18 }}>Yes</div>
        </Item>
        <Item>
          <RadioButton
            type="radio"
            checked={teamLocation === 'no'}
            onChange={handleChange}
            value="no"
            name="noButton"
          />
          <RadioButtonLabel />
          <div style={{ color: '#777', fontSize: 18 }}>No</div>
        </Item>
        <Item>
          <RadioButton
            type="radio"
            checked={teamLocation === 'maybe'}
            onChange={handleChange}
            value="maybe"
            name="maybeButton"
          />
          <RadioButtonLabel />
          <div style={{ color: '#777', fontSize: 18 }}>Maybe</div>
        </Item>
      </RadioWrap>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 800px) {
    width: 80%;
    margin-left: 20px;
  }
`

const Header = styled.h3`
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  padding-right: 2vh;
  flex: 1;
  @media (max-width: 800px) {
    width: 80%;
  }
`

const RadioWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: transparent;
  border: 3px solid #ff9000;
`
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;

  &:checked + ${RadioButtonLabel} {
    background: #ff9000;
    border: 1px solid #ff9000;
  }
`
