import React, { useEffect, useState } from 'react'
// plugin that creates slider
import Slider from 'nouislider'
import styled from 'styled-components'
import '../../css/slider.scss'

export default function TeamSize(props) {
  const [teamSize, setTeamSize] = useState(1)
  useEffect(() => {
    if (
      !document
        .getElementById('teamSizeSlider')
        .classList.contains('noUi-target')
    ) {
      Slider.create(document.getElementById('teamSizeSlider'), {
        start: teamSize,
        connect: [true, false],
        step: 1,
        range: { min: 1, max: 50 },
      }).on('update', values => {
        setTeamSize(Math.round(values))
        props.getTeamSize(Math.round(values))
      })
    }
    return function cleanup() {}
  })
  const peopleLabel = teamSize === 1 ? ' person' : ' people'
  return (
    <Wrapper>
      <Header>What size team do you need?</Header>
      <Label>{teamSize + peopleLabel}</Label>
      <div id="teamSizeSlider" className="slider-primary" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 800px) {
    width: 80%;
    margin-left: 20px;
  }
`

const Header = styled.h3`
  font-size: 22px;
  color: #ffffff;
  font-weight: bold;
  padding-right: 2vh;
  flex: 1;
  @media (max-width: 800px) {
    width: 80%;
  }
`

const Label = styled.h3`
  text-align: center;
  margin-bottom: 2vh;
  color: #999;
  @media (max-width: 800px) {
    font-size: 22px;
  }
`
